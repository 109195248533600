body {
    background-color: lightgray;
}
.has-error {
    color: #f5365c;
}

.has-error select {
    border: 1px solid #f5365c;
}

.has-error input {
    border: 1px solid #f5365c;
}
